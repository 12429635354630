<template>
  <div class="room-composition">
    <div class="row">
      <div class="col-lg-12">
        <div class="phone_right" v-if="text">
          <h4>{{ $t("product-page.for-your-information") }}</h4>
          <ul v-html="text"></ul>
        </div>
      </div>
      <!-- <div class="col-lg-4">
        <div class="phone_box">
          <div class="iconphone">
            <img src="assets/img/phone.png" alt="" />
          </div>
          {{ $t("product-page.encounter-message") }}
          <p style="text-align:center; font-size:25px;">050-8308880</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    information: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      text: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    lang() {
      this.changeText();
    },
  },
  beforeMount() {
    this.changeText();
  },
  methods: {
    changeText() {
      let information = this.information
        ? this.information
        : ''; // this.$t('product-page.product-detail-information');
      information = information.replaceAll('\n\n', '\n');
      this.text = information;
      // this.text = information
      //   .split(".")
      //   .map((item) => {
      //     return `<li>${item}.</li>`;
      //   })
      //   .join("");
    },
  },
};
</script>
<style>
  .phone_right div, .phone_right ul {
    width: auto !important;
  }
</style>
